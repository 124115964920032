import Navigation from "./components/navigation";
import Footer from "./components/footer";
import LogoCloud from "./components/logo-cloud";
import HeadingSection from "./components/heading-section";
import { Badge } from "./components/badge";
import HeadingTitle from "./components/heading-title";

function App() {
  return (
    <div className="mx-auto max-w-5xl px-4 ">
      <Navigation />

      <header>
        <h1 className="text-[#f7f8f8] pt-16 sm:pt-32 text-2xl sm:text-5xl leading-tight tracking-tight font-semibold animate-unblur-2 max-w-3xl">
          A unified desktop experience for modern Kubernetes operations
        </h1>

        <h2 className="text-[#8a8f98] pt-8 pb-16 sm:pb-32 text-lg sm:text-2xl tracking-tighter font-medium animate-unblur-2.5 max-w-2xl">
          <span className="text-[#f7f8f8]">One interface.</span> Bringing all
          your clusters, applications, and existing tools beautifully into
          focus.
        </h2>
        <img
          src="/image1.png"
          alt="Black Citadel"
          className="w-full animate-unblur-2"
        />
      </header>

      <LogoCloud />

      <HeadingSection
        title="Our vision"
        anchor="our-vision"
        className="bg-green-500"
      />

      <HeadingTitle>Unifying the Kubernetes ecosystem</HeadingTitle>

      <p className="text-[#8a8f98] pb-6 text-lg sm:text-xl tracking-tighter font-medium">
        The Kubernetes ecosystem has given us powerful tools for deployment,
        observability, and operations. While each tool excels at its specific
        task, teams spend precious time switching between interfaces, piecing
        together the complete picture.
      </p>

      <p className="text-[#8a8f98] pb-16 text-lg sm:text-xl tracking-tighter font-medium">
        <span className="text-[#f7f8f8]">We're changing that.</span> By unifying
        these tools' APIs into a single, thoughtfully designed interface, we're
        not just eliminating context switching - we're creating new
        possibilities.
      </p>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-0 mb-16">
        <div className="bg-[#101010] p-12 sm:border sm:border-[#2a2a2a] sm:border-l-0">
          <h4 className="text-[#f7f8f8] text-lg sm:text-2xl font-semibold pb-4">
            Cross-platform by design
          </h4>
          <p className="text-[#8a8f98] text-sm sm:text-base pb-8">
            Built natively for Windows, macOS, and Linux - use your preferred
            operating system without compromise.
          </p>
          <div className="flex justify-left space-x-6">
            <img src="/windows.svg" className="h-10 w-10" alt="Windows" />
            <img src="/macos.svg" className="h-10 w-10 " alt="macOs" />
            <img src="/linux.svg" className="h-8 w-8 mt-1" alt="Linux" />
          </div>
        </div>
        <div className="bg-[#101010] p-12 sm:border sm:border-[#2a2a2a] sm:border-r-0 sm:border-l-0">
          <h4 className="text-[#f7f8f8] text-lg sm:text-2xl font-semibold pb-4">
            API-native integrations
          </h4>
          <p className="text-[#8a8f98] text-sm sm:text-base pb-8">
            Connects directly to your existing tools' APIs - no cluster
            modifications or installations required.
          </p>
          <div className="flex justify-left space-x-1 md:space-x-6">
            <Badge color="service">kubectl</Badge>
            <Badge color="pod">ArgoCD API</Badge>
            <Badge color="deployment">Prometheus API</Badge>
          </div>
        </div>
      </div>

      <HeadingSection
        title="The roadmap"
        anchor="the-roadmap"
        className="bg-blue-500"
      />

      <HeadingTitle>Building the future of Kubernetes operations</HeadingTitle>

      <p className="text-[#8a8f98] pb-6 text-lg sm:text-xl tracking-tighter font-medium">
        We're building in the open, together with our users. Our public roadmap
        ensures transparency in where we're headed, while enabling us to adapt
        based on your feedback and real-world needs.
      </p>

      <p className="text-[#8a8f98] text-lg sm:text-xl tracking-tighter font-medium">
        Your insights shape our priorities. Whether it's suggesting new
        integrations, refining existing features, or highlighting emerging use
        cases - your experience with Kubernetes helps guide what we build next.
      </p>

      <HeadingSection
        title="Get started"
        anchor="get-started"
        className="bg-fuchsia-500"
      />

      <HeadingTitle>Early adopter pricing</HeadingTitle>

      <p className="text-[#8a8f98] pb-6 text-lg sm:text-xl tracking-tighter font-medium">
        To ensure we can fully commit to building our vision and delivering the
        best possible experience, we've made this a paid product.
      </p>

      <p className="text-[#8a8f98] text-lg sm:text-xl tracking-tighter font-medium">
        We're offering special early adopter pricing to users joining us on this
        journey. Get access to all current and future features at a
        significantly reduced rate. As we add more features and integrations,
        our pricing will evolve to reflect the growing value.
      </p>

      <Footer />
    </div>
  );
}

export default App;
