export default function LogoCloud() {


    

    return (
        <div className="py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <h4 className="text-[#f7f8f8] pb-16 text-lg sm:text-2xl tracking-tighter font-medium text-center">
                    Powered with the tools you already depend on.
                </h4>

                <div className="mx-auto grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-5">
                    <img
                        alt="Kubernetes"
                        src="/logo-cloud/kubernetes.svg"
                        width={158}
                        height={48}
                        className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                    />
                    <img
                        alt="ArgoCD"
                        src="/logo-cloud/argocd.svg"
                        width={158}
                        height={48}
                        className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                    />
                    <img
                        alt="Prometheus"
                        src="/logo-cloud/prometheus.svg"
                        width={158}
                        height={48}
                        className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                    />
                    <img
                        alt="Grafana"
                        src="/logo-cloud/grafana.svg"
                        width={158}
                        height={48}
                        className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
                    />
                    {/* <img
                        alt="Jaeger"
                        src="/logo-cloud/jaeger.svg"
                        width={158}
                        height={48}
                        className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
                    /> */}
                                        <img
                        alt="AWS"
                        src="/logo-cloud/aws.svg"
                        width={100}
                        height={30}
                        className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                    />
                    {/* <img
                        alt="Cert Manager"
                        src="/logo-cloud/cert-manager.svg"
                        width={158}
                        height={48}
                        className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
                    />
                    <img
                        alt="GCP"
                        src="/logo-cloud/gcp.svg"
                        width={158}
                        height={48}
                        className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                    />

                    <img
                        alt="Azure"
                        src="/logo-cloud/azure.svg"
                        width={158}
                        height={48}
                        className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                    />
                    <img
                        alt="CNCF"
                        src="/logo-cloud/cncf.svg"
                        width={158}
                        height={48}
                        className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
                    /> */}
                </div>
            </div>
        </div>
    )
}
